<template>
  <div class="channel">
    <helmet>
      <title>{{$t('title.channel', {channel_name: channel.name})}}</title>
    </helmet>
    <!--    <breadcrumb>-->
    <!--      <router-link to="/dashboard">{{$t('common.dashboard')}}</router-link>-->
    <!--      <router-link :to="`/channel/${channel.cid}`">{{channel.name}}</router-link>-->
    <!--    </breadcrumb>-->
    <div class="channel__header-row">
      <TitleHeader backTo="/dashboard">{{channel.name}}</TitleHeader>
    </div>
    <div class="font-medium">
      <span class="mr-4">
        {{$t('channel.labels.subscription')}}
      </span>
      <span :class="{'text-red-600': subscriptionEnded}">{{subscriptionText}}</span>
    </div>

    <div class="channel__tabs">
      <div class="channel__tabs-header-wrapper">
        <div class="channel__tabs-header">
          <router-link
            v-for="tab in tabs"
            class="channel__tab-button"
            :to="{name: `channel_${tab}`}"
            :key="tab">
            {{$t(`channel.tabs.${tab}`)}}
          </router-link>
        </div>
      </div>
      <div class="channel__tab-content">
        <router-view :channel.sync="channel"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Helmet from '@/components/Helmet.vue'

  import Card from '@/components/Card.vue'
  import CardHeader from '@/components/CardHeader.vue'

  import TitleHeader from '@/components/TitleHeader.vue'
  import Breadcrumb from '@/components/Breadcrumb.vue'

  import Blinkable from '@/components/Blinkable.vue'

  import subscriptionMethodsMixin from '@/mixins/subscriptionMethods'

  import store from '@/store'
  import { chain } from '@/functions/utils'
  import {
    ensureCheckAuth,
    ensureGetChannels,
    ensureGetUser,
  } from '@/functions/data'

  import {
    apiSetLanguage,
  } from '@/functions/api'

  export default {
    name: 'Channel',
    mixins: [
      subscriptionMethodsMixin,
    ],
    components: {
      Helmet,

      Card,
      CardHeader,

      TitleHeader,
      Breadcrumb,

      Blinkable,
    },
    data: () => ({
      tabs: ['posts'/*, 'templates'*/, 'admins', 'settings'],
    }),
    computed: {
      channels() {
        return this.$store.state.auth.userChannels
      },
      channel: {
        get() {
          return this.channels.find(c => c.cid === Number(this.$route.params.id))
        },
        set(v) {
          this.$store.commit('auth/setChannel', v)
        },
      },

      subscriptionText() {
        return this.getSubscriptionText(this.channel)
      },
      subscriptionEnded() {
        return this.isSubscriptionEnded(this.channel)
      },
    },
    beforeRouteEnter(to, from, next) {
      return chain(
        {to, from, store},
        next,
        [
          ensureCheckAuth,
          ensureGetUser,
          ensureGetChannels,
          ({to, store}) => {
            const channels = store.state.auth.userChannels

            if (!channels.find(c => c.cid === Number(to.params.id))) {
              return {
                ok: false,
                next: 'dashboard',
              }
            }
            return {
              ok: true,
            }
          },
        ]
      )
    },
  }
</script>

<style scoped lang="stylus">
  mobile = "(max-width 599px)"

  .channel
    @apply px-12 pt-3 pb-12

    flex-grow 1

    @media mobile
      @apply px-6

  .channel__back-button
    @apply mr-3
    position relative
    top 0.55rem

    @media mobile
      top .75rem

  .channel__tabs
    @apply mt-4

  .channel__tabs-header-wrapper
    overflow-y hidden

  .channel__tabs-header
    display flex
    overflow-x auto
    @apply border-b border-solid border-blue-500

  .channel__tab-button
    position relative
    display inline-block
    @apply font-medium text-blue-500
    @apply px-5 py-3 rounded-t
    cursor pointer

    &:hover
      @apply bg-blue-100

    &.router-link-exact-active
      @apply bg-blue-500 text-white

      &:hover
        @apply bg-blue-600

  .input
    width 0
    height 0
    position absolute
    z-index -1
    opacity 0

  .channel__cards
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-template-rows auto auto 1fr
    grid-template-areas "posts state" "posts admins" "posts -"
    grid-column-gap 15px
    grid-row-gap 15px

    @media (max-width 1600px)
      grid-template-columns repeat(2, 1fr)
      grid-template-areas "state admins" "posts posts"

    @media (max-width 600px)
      grid-template-columns 1fr
      grid-template-areas "state" "admins" "posts"

    .state
      grid-area state

    .admins
      grid-area admins

    .posts
      grid-area posts


  .table
    @apply m-0

    tr
      @apply border-t border-gray-300

    td, th
      @apply p-3

      &:not(:last-child)
        @apply border-r border-gray-300

    .subscription-ended td
      background-color #fef0f0

  .channel__button-td
    padding .25rem !important

  .language-icon
    display inline-block
    width 17px
    height 17px
    margin-bottom 3px
    background-size contain
    vertical-align middle
    @apply mr-1

    &.ru
      background-image url('~@/assets/flags/ru.png')

    &.en
      background-image url('~@/assets/flags/en.png')

    &.ua
      background-image url('~@/assets/flags/ua.png')

    &.uz
      background-image url('~@/assets/flags/uz.png')

</style>

<style lang="stylus">
  .id-code
    color inherit
    background #eee
    padding 2px 5px
    border-radius 5px

  .cell
    display flex
    flex-direction row
    align-items center
</style>
